// src/components/SecondaryNavBar.js
import { Navbar, Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export const SecondaryNavBar = ({ category = "Default Category", projectId = "Default Project" }) => {
  const navigate = useNavigate();

  // 点击 category 时返回上一步，然后在主页中处理历史记录
  const handleBackClick = () => {
    navigate(-1); // 执行返回操作
  };

  return (
    <Navbar expand="md" className="secondary-navbar">
      <Container>
        <div className="navbar-link-group">
          <span onClick={handleBackClick} className="navbar-link" style={{ cursor: 'pointer' }}>
            {category.toUpperCase()}
          </span>
          <span className="separator">/</span>
          <span className="navbar-link">{projectId.toUpperCase()}</span>
        </div>
      </Container>
    </Navbar>
  );
};