// src/components/Projects.js
import { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projectData from "../Data/ProjectData"; 
import colorSharp2 from "../assets/img/color-sharp2.png";

export const Projects = () => {
  // 从 localStorage 获取上次的激活状态，没有则默认 'first'
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'first');

  // 处理用户点击时更新 activeTab 并存储到 localStorage
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
    localStorage.setItem('activeTab', selectedTab); // 存储激活状态
  };

  // 组件加载时读取 localStorage 并设置激活状态
  useEffect(() => {
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  const projects = Object.values(projectData);

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Projects</h2>
            <Tab.Container id="projects-tabs" activeKey={activeTab} onSelect={handleTabSelect}>
              <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center">
                <Nav.Item>
                  <Nav.Link eventKey="first">Personal Project</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Team Project</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Other Works</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects
                      .filter(project => project.category === "Personal")
                      .map((project, index) => (
                        <ProjectCard key={index} {...project} />
                      ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row>
                    {projects
                      .filter(project => project.category === "Team")
                      .map((project, index) => (
                        <ProjectCard key={index} {...project} />
                      ))}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row>
                    {projects
                      .filter(project => project.category === "Other")
                      .map((project, index) => (
                        <ProjectCard key={index} {...project} />
                      ))}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="background" />
    </section>
  );
};