const projectData = {
    lingualink: {
      id: "lingualink",
      title: "LINGUALINK",
      description: "HCI Design & Development",
      images: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img1.jpg'),
      category: "Personal"
    },
    guqin: {
      id: "guqin",
      title: "Guqin",
      description: "HCI Design & Development",
      images: [11, 12, 13, 14, 15].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img2.jpg'),
      category: "Personal"
    },
    badboar: {
      id: "badboar",
      title: "BadBoar",
      description: "Service Design",
      images: [16, 17, 18, 19, 20, 21, 22, 23].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img3.jpg'),
      category: "Personal"
    },
    dreams_of_Sophobird: {
      id: "dreams_of_Sophobird",
      title: "Sophobird",
      description: "Game Design",
      images: [24, 25, 26, 27, 28, 29].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img4.jpg'),
      category: "Personal"
    },
    speechifier: {
      id: "speechifier",
      title: "Speechifier",
      description: "HCI & User Experience Design",
      images: [30, 31, 32, 33, 34, 35, 36].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img5.jpg'),
      category: "Personal"
    },

    linkbricks: {
      id: "linkbricks",
      title: "linkBricks",
      description: "HCI Design",
      images: [30, 31, 32, 33, 34, 35, 36].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img9.jpg'),
      category: "Team",
      },

    mafalda: {
      id: "mafalda",
      title: "Mafalda",
      description: "Installation Art Exhibition",
      images: [].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img7.jpg'),
      category: "Team",
     },

     waven: {
      id: "waven",
      title: "Waven",
      description: "HCI Design",
      images: [].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img11.jpg'),
      category: "Team",
     },

     electronic_nose: {
      id: "electronic_nose",
      title: "Electronic Nose",
      description: "HCI Design",
      images: [].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img10.jpg'),
      category: "Team",
     },
 
    cornexa: {
      id: "cornexa",
      title: "Cornexa & AI stock analyst",
      description: "UI & UX Design and Development",
      images: [].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img6.jpg'),
      category: "Team",
      },
   
    future_eye: {
      id: "future_eye",
      title: "Future Eye",
      description: "Installation Art Exhibition",
      images: [30, 31, 32, 33, 34, 35, 36].map(num => require(`../portfolio/${num}.jpg`)),
      imgUrl: require('../assets/img/project-img8.jpg'),
      category: "Other",
      },
    
      
  };

  
  export default projectData;