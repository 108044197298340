import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './CornexaDetail.css';

// 导入 Logo 图片
import logo1 from '../../assets/img/logo1.svg';
import logo2 from '../../assets/img/logo2.svg';

// 导入 Work Process 图片
import workProcess1 from '../../portfolio/work-process-1.png';
import workProcess2 from '../../portfolio/work-process-2.png';
import workProcess3 from '../../portfolio/work-process-3.png';
import workProcess4 from '../../portfolio/work-process-4.png';
import workProcess5 from '../../portfolio/work-process-5.png';
import workProcess6 from '../../portfolio/work-process-6.png';

// 导入 Outcome 图片
import outcome1 from '../../portfolio/outcome-1.png';
import outcome2 from '../../portfolio/outcome-2.png';
import outcome3 from '../../portfolio/outcome-3.png';
import outcome4 from '../../portfolio/outcome-4.png';
import outcome5 from '../../portfolio/outcome-5.png';
import outcome6 from '../../portfolio/outcome-6.png';
import outcome7 from '../../portfolio/outcome-7.png';
import outcome8 from '../../portfolio/outcome-8.png';

export const CornexaDetail = () => {
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  // 确保图片加载完成后滚动到顶部
  useEffect(() => {
    const images = [
      logo1,
      logo2,
      workProcess1,
      workProcess2,
      workProcess3,
      workProcess4,
      workProcess5,
      workProcess6,
      outcome1,
      outcome2,
      outcome3,
      outcome4,
      outcome5,
      outcome6,
      outcome7,
      outcome8,
    ];

    const loadImages = () =>
      Promise.all(
        images.map(
          (src) =>
            new Promise((resolve) => {
              const img = new Image();
              img.src = src;
              img.onload = resolve;
            })
        )
      );

    loadImages().then(() => {
      setAllImagesLoaded(true);
      window.scrollTo(0, 0); // 滚动到顶部
    });
  }, []);

  if (!allImagesLoaded) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div>
      {/* 项目背景 */}
      <section className="section">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title">Project Background</h2>
              <p className="section-text">
                My three classmates with computer science backgrounds and I formed a startup team. With the investment
                collaboration of a local Macau company, we successfully developed two AIGC platforms, which were
                launched in Macau, China in September 2023. In both projects, I was involved in the entire design
                process, oversaw the workflow, and participated in front-end development. <br />
                <strong>Year: 2023.2 - 2023.9</strong>
              </p>
            </Col>
          </Row>
         
          <Row className="mt-5">
            <Col lg={6}>
              <div className="project-header">
                <img src={logo1} className="project-logo" alt="Cornexa Logo" />
                <div>
                  <h3>Cornexa</h3>
                  <h4>AI Application Development Community</h4>
                  <p className="section-text">
                    Dedicated to building a people-centric tech community where freelancers can find their own niche and
                    collectively create a world that is economically equitable and technologically advanced.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="project-header">
                <img src={logo2} className="project-logo" alt="AI Stock Analyst Logo" />
                <div>
                  <h3>AI Stock Analyst</h3>
                  <h4>Intelligent Forecasting and Market Insight</h4>
                  <p className="section-text">
                    Independently researched A.I. stock-picking system to assist in selecting high-quality Hong Kong stocks
                    (FinList HK), supported by cutting-edge artificial intelligence and the latest market data.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          
        </Container>
      </section>

      {/* 工作流程 */}
      <section className="section">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title">Work Process</h2>
            </Col>
          </Row>
          <Row className="mt-4">
            {[workProcess1, workProcess2, workProcess3, workProcess4, workProcess5, workProcess6].map((imgSrc, index) => (
              <Col lg={4} md={4} sm={6} className="mb-4" key={index}>
                <img src={imgSrc} className="img-fluid rounded" alt={`Work Process ${index + 1}`} />
                <p className="section-text mt-4">
                  <strong>Step {index + 1}:</strong>
                  {index === 0 && "Brainstorming and ideation process for the project."}
                  {index === 1 && "Team formation and role allocation."}
                  {index === 2 && "Establishing cloud-based team collaboration."}
                  {index === 3 && "Designing the core functionalities and testing/debugging."}
                  {index === 4 && "Finalizing the launch event schedule."}
                  {index === 5 && "Official market launch."}
                </p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* 项目成果 */}
      <section className="section">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title">Outcome</h2>
            </Col>
          </Row>
          <Row className="mt-4">
            {[outcome1, outcome2, outcome3, outcome4, outcome5, outcome6, outcome7, outcome8].map((imgSrc, index) => (
              <Col lg={6} md={6} sm={12} className="mb-4" key={index}>
                <img src={imgSrc} className="img-fluid rounded" alt={`Outcome ${index + 1}`} />
                <p className="section-text mt-4">
                  {index === 0 && "Cornexa homepage (link: www.cornexa.ai)."}
                  {index === 1 && "AI Innovation Project Competition Poster."}
                  {index === 2 && "AI Stock Analyst homepage."}
                  {index === 3 && "Startup team and partner introduction page."}
                  {index === 4 && "Successfully pitched to an investment team."}
                  {index === 5 && "Hosted a press conference."}
                  {index === 6 && "Captured moments from the press conference."}
                  {index === 7 && "Team photo taken at the press conference."}
                </p>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CornexaDetail;