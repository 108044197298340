import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import projectData from '../Data/ProjectData';
import { SecondaryNavBar } from './SecondaryNavBar';
import { CornexaDetail } from './project/CornexaDetail';
import { MafaldaDetail } from './project/MafaldaDetail';

const detailComponents = {
  cornexa: CornexaDetail,
  mafalda: MafaldaDetail,
};

export const ProjectDetail = () => {
  const { projectId } = useParams();
  const project = projectData[projectId];
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [projectId]);

  useEffect(() => {
    setLoadedImages({});
    const images = project?.images || [];
    images.forEach((img, index) => {
      const image = new Image();
      image.src = img;
      image.onload = () => {
        setLoadedImages((prev) => ({ ...prev, [index]: true }));
      };
    });
  }, [projectId, project?.images]); // 添加依赖

  if (!project) return <p>Project not found</p>;

  const DetailComponent = detailComponents[projectId] || null;

  return (
    <div>
      <SecondaryNavBar category={project.category} projectId={project.title} />
      <Container>
        {DetailComponent ? (
          <DetailComponent />
        ) : (
          <div className="project-detail">
            <div className="project-images">
              {project.images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`${project.title} ${index + 1}`}
                  className={`project-img ${loadedImages[index] ? 'loaded' : ''}`}
                />
              ))}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};